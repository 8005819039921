.footer-wrapper {
  background-color: #0f0e13;
  padding: 50px 5.5%;
}
.about-us-wrapper {
  display: none;
}
.footer-ecell-logo {
  width: 170px;
}

.footer-about-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.82rem;
  // line-height: 15px;
  /* or 125% */

  text-align: justify;

  color: #9896a2;
}

.footer-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.footer-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.375rem;
  // line-height: 32px;
  /* identical to box height, or 160% */

  text-transform: capitalize;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.contact-follow-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contact-us-wrapper,
.quick-links-wrapper,
.emails-list-wrapper,
.links-wrapper {
  display: flex;
  flex-direction: column;
  // gap: 20px;
}

.links-wrapper,
.emails-list-wrapper {
  gap: 20px;
}

.links-wrapper {
  height: 100%;
  justify-content: space-between;
}

.quick-link,
.quick-link:visited {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  // line-height: 32px;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.5px;
}

.quick-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.email-link,
.email-link:visited {
  display: flex;
  gap: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 20px;
  color: rgba(255, 255, 255, 0.75);
}

.email-link:hover {
  i {
    color: rgba(255, 255, 255, 0.9);
  }
  a {
    color: rgba(255, 255, 255, 0.9);
  }
}

.follow-us-wrapper {
  display: flex;
  flex-direction: column;
  // gap: 20px;
}

.icon-list-wrapper {
  display: flex;
  gap: 24px;
}

.social-media-cont {
  width: 40px;
  height: 40px;
  padding: 15px;
  border: 0.5px solid rgba(255, 255, 255, 0.75);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.75);
  }

  &:nth-of-type(2) {
    i {
      font-size: 20px;
    }
  }
  &:nth-of-type(3) {
    i {
      font-size: 20px;
    }
  }
}

.social-media-cont:hover {
  border: 1px solid rgb(255, 255, 255);
  i {
    color: #ffffff;
  }
}

.address-wrapper {
  display: flex;
  // gap: 20px;
  flex-direction: column;
}

.contact-map iframe {
  border-radius: 10px;
  height: 175px;
}

.address-link {
  display: flex;
  gap: 13px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  // line-height: 15px;
  /* or 150% */
  text-align: justify;
  color: rgba(255, 255, 255, 0.75);
  margin-top: 1rem;
  i {
    font-size: 20px;
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
  }
}
.copyright-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-rule {
  border: 1px solid #3b3944;
  // margin: 0px;
  width: 100%;
}

.copyright-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  // line-height: 32px;
  /* or 320% */
  text-transform: capitalize;

  color: #888499;

  mix-blend-mode: normal;
  opacity: 0.75;
}

@media screen and (min-width: 1200px) {
  .about-us-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.563rem;
    max-width: 15.75rem;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 500px) {
  .address-wrapper,
  .contact-map iframe {
    max-width: 19rem;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
