.footer-wrapper {
  background-color: #0f0e13;
  padding: 50px 5.5%;
}

.about-us-wrapper {
  display: none;
}

.footer-ecell-logo {
  width: 170px;
}

.footer-about-text {
  text-align: justify;
  color: #9896a2;
  font-family: Poppins;
  font-size: .82rem;
  font-style: normal;
  font-weight: 400;
}

.footer-container {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 2rem;
  display: flex;
}

.footer-heading {
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 1.5rem;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
}

.contact-follow-wrapper {
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.contact-us-wrapper, .quick-links-wrapper, .emails-list-wrapper, .links-wrapper {
  flex-direction: column;
  display: flex;
}

.links-wrapper, .emails-list-wrapper {
  gap: 20px;
}

.links-wrapper {
  justify-content: space-between;
  height: 100%;
}

.quick-link, .quick-link:visited {
  color: #ffffffb3;
  letter-spacing: .5px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.quick-link:hover {
  color: #fffc;
}

.email-link, .email-link:visited {
  color: #ffffffbf;
  gap: 14px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

.email-link:hover i, .email-link:hover a {
  color: #ffffffe6;
}

.follow-us-wrapper {
  flex-direction: column;
  display: flex;
}

.icon-list-wrapper {
  gap: 24px;
  display: flex;
}

.social-media-cont {
  border: .5px solid #ffffffbf;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 15px;
  display: flex;
}

.social-media-cont i {
  color: #ffffffbf;
  font-size: 18px;
}

.social-media-cont:nth-of-type(2) i, .social-media-cont:nth-of-type(3) i {
  font-size: 20px;
}

.social-media-cont:hover {
  border: 1px solid #fff;
}

.social-media-cont:hover i {
  color: #fff;
}

.address-wrapper {
  flex-direction: column;
  display: flex;
}

.contact-map iframe {
  border-radius: 10px;
  height: 175px;
}

.address-link {
  text-align: justify;
  color: #ffffffbf;
  gap: 13px;
  margin-top: 1rem;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

.address-link i {
  font-size: 20px;
}

.address-link p {
  margin-bottom: 0;
  font-size: 12px;
}

.copyright-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer-rule {
  border: 1px solid #3b3944;
  width: 100%;
}

.copyright-text {
  text-transform: capitalize;
  color: #888499;
  mix-blend-mode: normal;
  opacity: .75;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (width >= 1200px) {
  .about-us-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1.563rem;
    max-width: 15.75rem;
    display: flex;
  }
}

@media screen and (width >= 500px) {
  .address-wrapper, .contact-map iframe {
    max-width: 19rem;
  }
}

hr {
  color: inherit;
  opacity: .25;
  background-color: currentColor;
  border: 0;
  margin: 1rem 0;
}
/*# sourceMappingURL=index.e7895e7a.css.map */
